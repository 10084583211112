<template>
  <div ref="container" id="picture-input" class="picture-input">
    <div v-if="hasPreview" class="preview-container">
      <div class="image-box" ref="imageBox">
        <canvas
          ref="canvas"
          class="picture-preview bordered-canvas"
          tabindex="0"
          :class="computedClasses"
          @drag.stop.prevent=""
          @dragover.stop.prevent=""
          @dragstart.stop.prevent=""
          @dragend.stop.prevent=""
          @dragenter.stop.prevent="onDragEnter"
          @dragleave.stop.prevent="onDragLeave"
          @drop.stop.prevent="onFileDrop"
          @mousedown="startMove"
          @touchstart="(ev) => startMove(ev.touches[0])"
          @mousemove="movePicture"
          @touchmove="(ev) => movePicture(ev.touches[0])"
          @mouseup="endMove"
          @touchend="(ev) => endMove(ev.changedTouches[0])"
          @click.prevent="onClick"
          @keyup.enter="onClick"
          :style="{
            height: previewHeight + 'px',
            zIndex: zIndex + 1,
          }">
        </canvas>
        <div
          v-if="!imageSelected"
          class="canvas-overlay"
          :style="{
            top: -previewHeight + 'px',
            marginBottom: -previewHeight + 'px',
            height: previewHeight - padding * 3 + 'px',
            zIndex: zIndex + 2,
          }">
          <span v-if="supportsDragAndDrop" class="canvas-overlay-text">{{
            dragMessage
          }}</span>
          <span v-else class="canvas-overlay-text">{{ tapMessage }}</span>
        </div>
      </div>
      <div class="preview-tools">
        <div
          class="preview-icon"
          ref="fitToPage"
          :class="[imageSelected ? 'active' : 'inactive']"
          @click="fitToPage"
          v-if="allowEdits">
          <FitToPageIcon></FitToPageIcon>
        </div>
        <div
          class="preview-icon"
          :class="[imageSelected ? 'active' : 'inactive']"
          @click="zoom(5)"
          v-if="allowEdits">
          <MagnifyPlusIcon></MagnifyPlusIcon>
        </div>
        <div
          class="preview-icon"
          :class="[imageSelected ? 'active' : 'inactive']"
          @click="zoom(-5)"
          v-if="allowEdits">
          <MagnifyMinusIcon></MagnifyMinusIcon>
        </div>
        <div
          class="preview-icon active youtube-icon"
          @click="onLinkClick"
          v-if="!allowEdits">
          <YoutubeIcon></YoutubeIcon>
        </div>
        <div
          class="preview-icon active"
          @click="onLinkClick"
          v-if="!allowEdits">
          <LinkIcon></LinkIcon>
        </div>
        <div
          v-if="imageSelected"
          class="preview-icon"
          :class="[imageSelected ? 'active' : 'inactive']"
          @click="selectImage">
          <NewBoxIcon></NewBoxIcon>
        </div>
        <div
          class="preview-icon"
          :class="[imageSelected ? 'active' : ['inactive', 'disabled']]"
          @click="removeImage">
          <DeleteIcon></DeleteIcon>
        </div>
      </div>
    </div>
    <input
      ref="fileInput"
      type="file"
      :name="name"
      id="real_input"
      accept="image/*"
      @change="onFileChange" />
    <modal-popup
      class="link-input"
      height="fit-content"
      :show="showLinkPopup"
      @close="showLinkPopup = false">
      <template v-slot:body>
        <input-field
          v-model="customURL"
          ref="linkinput"
          :data="{
            label: 'Add an image/video url or a YouTube url',
          }"></input-field>
      </template>
      <template v-slot:buttons>
        <div class="button-container">
          <button
            class="button button-primary-filled"
            @click="submitCustomURL"
            :class="{ disabled: imageLoading }">
            submit
          </button>
          <button
            class="button button-danger"
            @click="
              () => {
                customURL = '';
                showLinkPopup = false;
                usingCustomURL = false;
              }
            ">
            cancel
          </button>
        </div>
      </template>
    </modal-popup>
  </div>
</template>

<script>
import MagnifyPlusIcon from "vue-material-design-icons/MagnifyPlus.vue";
import MagnifyMinusIcon from "vue-material-design-icons/MagnifyMinus.vue";
import DeleteIcon from "vue-material-design-icons/Delete.vue";
import NewBoxIcon from "vue-material-design-icons/NewBox.vue";
import LinkIcon from "vue-material-design-icons/Link.vue";
import FitToPageIcon from "vue-material-design-icons/FitToPageOutline.vue";
import YoutubeIcon from "vue-material-design-icons/Youtube.vue";
import ModalPopup from "./basic/ModalPopup.vue";
import InputField from "./InputField.vue";
import { imageMixin } from "@/mixins/imageMixin.js";
export default {
  name: "PictureInput",
  components: {
    MagnifyPlusIcon,
    MagnifyMinusIcon,
    DeleteIcon,
    NewBoxIcon,
    FitToPageIcon,
    LinkIcon,
    YoutubeIcon,
    ModalPopup,
    InputField,
  },
  mixins: [imageMixin],
  props: {
    modelValue: {
      //used to allow access to a v-model on this template
      type: Object,
    },
    name: {
      type: String,
      default: null,
    },
    max_size: {
      type: Number,
      default: 1200,
    },
    size: {
      type: Number,
      default: 250,
    },
    zIndex: {
      type: Number,
      default: 100,
    },
    filePrefix: {
      type: String,
      default: "image",
    },
    keepSquare: {
      type: Boolean,
      default: false,
    },
    allowEdits: {
      type: Boolean,
      default: true,
    },
    dragMessage: {
      type: String,
      default: "add an image",
    },
    tapMessage: {
      type: String,
      default: "add an image",
    },
  },
  data() {
    return {
      imageSelected: false,
      previewHeight: 250,
      previewWidth: 250,
      draggingOver: false,
      canvasWidth: 350,
      canvasHeight: 350,
      padding: 16,
      isMoving: false,
      lastMovePosition: { x: 0, y: 0 },
      drawnImageHeight: 0,
      drawnImageWidth: 0,
      image: {},
      yOffset: 0,
      xOffset: 0,
      resizeRemoveController: {},
      showLinkPopup: false,
      customURL: "",
      usingCustomURL: false,
      imageLoading: false,
    };
  },
  mounted() {
    this.resizeRemoveController = new AbortController();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize, {
        signal: this.resizeRemoveController.signal,
      });
      this.onResize();
    });
    if (this.hasPreview) {
      this.pixelRatio = Math.round(
        window.devicePixelRatio ||
          window.screen.deviceXDPI / window.screen.logicalXDPI
      );
      const canvas = this.$refs.canvas;
      if (this.keepSquare) {
        this.canvasWidth = Math.min(
          this.$refs.imageBox.clientWidth,
          this.$refs.imageBox.clientHeight
        );
        this.canvasHeight = Math.min(
          this.$refs.imageBox.clientWidth,
          this.$refs.imageBox.clientHeight
        );
        this.previewWidth =
          Math.min(
            this.$refs.container.clientWidth,
            this.$refs.container.clientHeight
          ) - this.padding;
        this.previewHeight =
          Math.min(
            this.$refs.container.clientWidth,
            this.$refs.container.clientHeight
          ) - this.padding;
      } else {
        this.canvasWidth = this.$refs.imageBox.clientWidth;
        this.canvasHeight = this.$refs.imageBox.clientHeight;
        this.previewWidth = this.$refs.container.clientWidth - this.padding;
        this.previewHeight = this.$refs.container.clientHeight - this.padding;
      }
      if (canvas.getContext) {
        this.context = canvas.getContext("2d");
        this.context.scale(this.pixelRatio, this.pixelRatio);
      }
    }
  },
  onUnmounted() {
    this.resizeRemoveController.abort();
  },
  methods: {
    onClick() {
      if (!this.imageSelected) {
        this.selectImage();
        return;
      }
      this.$emit("click");
    },
    onLinkClick() {
      this.showLinkPopup = true;
    },
    submitCustomURL() {
      if (this.customURL.endsWith(".gifv")) {
        this.customURL = this.customURL.slice(0, -1);
      }
      if (this.matchImage(this.customURL)) {
        this.usingCustomURL = true;
        this.loadImageFromURL(this.customURL);
      } else if (this.matchVideo(this.customURL)) {
        this.usingCustomURL = true;
        this.loadVideoFromURL(this.customURL);
      } else if (this.matchYoutube(this.customURL)) {
        this.usingCustomURL = true;
        this.showLinkPopup = false;
        let code = this.matchYoutube(this.customURL)[1];
        console.log(code);
        this.loadImageFromURL(this.getYoutubeThumb(code));
      } else {
        let errorText =
          "Please only submit image/video links, or a youtube video.";
        if (this.customURL == "") {
          errorText = "Please enter a url.";
        }
        if (this.$refs.linkinput.shownErrors.includes(errorText)) {
          this.$refs.linkinput.shake();
        } else {
          this.$refs.linkinput.shownErrors.push(errorText);
        }
      }
    },
    loadVideoFromURL(url) {
      let vid = document.createElement("video");
      vid.setAttribute("preload", "metadata");
      this.imageLoading = true;
      vid.addEventListener("loadedmetadata", () => {
        setTimeout(() => {
          vid.currentTime = 0.5;
        }, 100);
        vid.addEventListener("seeked", () => {
          this.$store.commit("setLoading", false);
          // Resize the image
          let canvas = this.$refs.canvas;
          let newHeight = vid.videoHeight;
          let newWidth = vid.videoWidth;
          if (newHeight > this.max_size || newWidth > this.max_size) {
            //if either height or width is larger than the specified max pixels, we need to shrink it down
            let ratio = newHeight / newWidth;
            if (newHeight > newWidth) {
              newHeight = this.max_size;
              newWidth = this.max_size / ratio;
            } else {
              newWidth = this.max_size;
              newHeight = this.max_size * ratio;
            }
          }
          canvas.width = this.keepSquare
            ? Math.min(this.max_size, Math.max(newWidth, newHeight)) //if we keep it square, then we either want the largest size being the biggest side of the image, but not bigger than max_size
            : newWidth;
          canvas.height = this.keepSquare
            ? Math.min(this.max_size, Math.max(newWidth, newHeight))
            : newHeight;
          this.drawnImageHeight = newHeight;
          this.drawnImageWidth = newWidth;
          this.imageSelected = true;

          this.showLinkPopup = false;
          canvas.getContext("2d").drawImage(vid, 0, 0, newWidth, newHeight);
          this.emitImage();
          vid.remove();
          this.imageLoading = false;
        });
      });
      this.$store.commit("setLoading", true);
      vid.src = url;
      vid.onerror = () => {
        this.$store.commit("setLoading", false);
        let errorText = "Invalid video URL";
        if (this.$refs.linkinput.shownErrors.includes(errorText)) {
          this.$refs.linkinput.shake();
        } else {
          this.$refs.linkinput.shownErrors.push(errorText);
        }
        this.imageLoading = false;
      };
    },
    loadImageFromURL(url) {
      let image = new Image();
      image.onload = () => {
        this.$store.commit("setLoading", false);
        // Resize the image
        let canvas = this.$refs.canvas;
        let newHeight = image.height;
        let newWidth = image.width;
        if (newHeight > this.max_size || newWidth > this.max_size) {
          //if either height or width is larger than the specified max pixels, we need to shrink it down
          let ratio = newHeight / newWidth;
          if (newHeight > newWidth) {
            newHeight = this.max_size;
            newWidth = this.max_size / ratio;
          } else {
            newWidth = this.max_size;
            newHeight = this.max_size * ratio;
          }
        }
        canvas.width = this.keepSquare
          ? Math.min(this.max_size, Math.max(newWidth, newHeight)) //if we keep it square, then we either want the largest size being the biggest side of the image, but not bigger than max_size
          : newWidth;
        canvas.height = this.keepSquare
          ? Math.min(this.max_size, Math.max(newWidth, newHeight))
          : newHeight;
        this.drawnImageHeight = newHeight;
        this.drawnImageWidth = newWidth;
        this.imageSelected = true;

        this.showLinkPopup = false;
        canvas.getContext("2d").drawImage(image, 0, 0, newWidth, newHeight);
        this.emitImage();
      };
      this.$store.commit("setLoading", true);
      image.src = url;
      image.onerror = () => {
        this.$store.commit("setLoading", false);
        let errorText = "Invalid URL";
        if (this.$refs.linkinput.shownErrors.includes(errorText)) {
          this.$refs.linkinput.shake();
        } else {
          this.$refs.linkinput.shownErrors.push(errorText);
        }
      };
    },
    movePicture(ev) {
      if (!this.imageSelected || !this.allowEdits) return;
      if (this.isMoving) {
        let moveX = ev.clientX - this.lastMovePosition.x;
        let moveY = ev.clientY - this.lastMovePosition.y;
        let newOffsetX = this.xOffset + moveX * 4;
        let newOffsetY = this.yOffset + moveY * 4;
        if (Math.abs(newOffsetX) < 25) newOffsetX = 0; //snapping to edges
        if (Math.abs(newOffsetY) < 25) newOffsetY = 0;
        if (
          Math.abs(
            this.drawnImageWidth + newOffsetX - this.$refs.canvas.width
          ) < 25
        ) {
          newOffsetX = this.$refs.canvas.width - this.drawnImageWidth;
        }
        if (
          Math.abs(
            this.drawnImageHeight + newOffsetY - this.$refs.canvas.height
          ) < 25
        ) {
          newOffsetY = this.$refs.canvas.height - this.drawnImageHeight;
        }
        this.$refs.canvas
          .getContext("2d")
          .clearRect(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);
        this.$refs.canvas
          .getContext("2d")
          .drawImage(
            this.image,
            newOffsetX,
            newOffsetY,
            this.drawnImageWidth,
            this.drawnImageHeight
          );
      }
    },
    startMove(ev) {
      if (!this.imageSelected || !this.allowEdits) return;
      this.isMoving = true;
      this.lastMovePosition = {
        x: ev.clientX,
        y: ev.clientY,
      };
    },
    endMove(ev) {
      if (!this.imageSelected || !this.allowEdits) return;
      this.isMoving = false;
      let moveX = ev.clientX - this.lastMovePosition.x;
      let moveY = ev.clientY - this.lastMovePosition.y;
      this.xOffset += moveX * 4;
      this.yOffset += moveY * 4;
      this.emitImage();
    },
    zoom(num) {
      let context = this.$refs.canvas.getContext("2d");
      let newHeight = this.drawnImageHeight;
      let newWidth = this.drawnImageWidth;
      newHeight *= (100 + num) / 100;
      newWidth *= (100 + num) / 100;
      let newXOffset =
        this.xOffset - Math.ceil((newWidth - this.drawnImageWidth) / 2);
      let newYOffset =
        this.yOffset - Math.ceil((newHeight - this.drawnImageHeight) / 2);
      context.clearRect(
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      context.drawImage(
        this.image,
        newXOffset,
        newYOffset,
        newWidth,
        newHeight
      );
      this.xOffset = newXOffset;
      this.yOffset = newYOffset;
      this.drawnImageHeight = newHeight;
      this.drawnImageWidth = newWidth;
      this.emitImage();
    },
    fitToPage() {
      let height = this.image.height;
      let width = this.image.width;
      let ratio = height / width;
      let maxSize = this.keepSquare
        ? this.max_size
        : Math.min(this.max_size * ratio, this.max_size / ratio);
      let newHeight = height > width ? maxSize : maxSize * ratio;
      let newWidth = width > height ? maxSize : maxSize / ratio;
      //check if its already fit to page
      if (
        this.drawnImageWidth == newWidth &&
        this.drawnImageHeight == newHeight
      ) {
        newHeight = height > width ? maxSize * ratio : maxSize;
        newWidth = width > height ? maxSize / ratio : maxSize;
      }

      let newOffsetX = this.keepSquare ? (maxSize - newWidth) / 2 : 0;
      let newOffsetY = this.keepSquare ? (maxSize - newHeight) / 2 : 0;
      let context = this.$refs.canvas.getContext("2d");
      let canvasWidth = this.keepSquare ? maxSize : newWidth;
      let canvasHeight = this.keepSquare ? maxSize : newHeight;
      this.$refs.canvas.height = canvasHeight;
      this.$refs.canvas.width = canvasWidth;
      context.clearRect(0, 0, canvasWidth, canvasHeight);
      context.drawImage(
        this.image,
        newOffsetX,
        newOffsetY,
        newWidth,
        newHeight
      );
      this.drawnImageWidth = newWidth;
      this.drawnImageHeight = newHeight;
      this.xOffset = newOffsetX;
      this.yOffset = newOffsetY;
      this.emitImage();
    },
    onResize() {
      this.resizeCanvas();

      if (this.imageObject) {
        this.drawImage(this.imageObject);
      }
    },
    onDragEnter() {
      if (!this.supportsDragAndDrop) {
        return;
      }
      this.draggingOver = true;
    },
    onDragLeave() {
      if (!this.supportsDragAndDrop) {
        return;
      }
      this.draggingOver = false;
    },
    onFileDrop(e) {
      this.onDragLeave();
      this.onFileChange(e);
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length || files.length > 1) {
        this.$emit("error", "Please upload one image.");
        return;
      }
      let file = files[0];
      if (!file.type.startsWith("image/")) {
        this.$emit("error", "Please upload an image.");
        return;
      }
      if (file.size <= 0) {
        this.$emit("error", "Your file is less than 0mb? How??");
      }
      if (this.lastModified === file.lastModified) {
        return; //dont waste time with the same photo
      }
      this.lastModified = file.lastModified;

      this.imageSelected = true;
      this.image = "";
      if (this.hasPreview) {
        this.loadImage(file);
      }
      return;
    },
    selectImage() {
      this.usingCustomURL = false;
      this.customURL = "";
      this.$refs.fileInput.click();
    },
    emitImage() {
      if (this.usingCustomURL) {
        this.$emit("customURL", this.customURL);
        return;
      }
      if (this.image === "") {
        this.$emit("update:modelValue", "");
        return;
      }
      this.$refs.canvas.toBlob((blob) => {
        let file = new File(
          [blob],
          this.filePrefix +
            new Date()
              .toISOString()
              .replaceAll(".", "")
              .replaceAll("-", "")
              .replaceAll(":", "") +
            ".png",
          {
            type: "image/png",
          }
        );
        this.$emit("update:modelValue", file);
        this.$emit("newfile", file);
      });
    },
    loadImage(file) {
      let reader = new FileReader();
      reader.onload = (readerEvent) => {
        let image = new Image();
        image.onload = () => {
          // Resize the image
          let canvas = this.$refs.canvas;
          let newHeight = image.height;
          let newWidth = image.width;
          if (newHeight !== newWidth && this.keepSquare) {
            //flash that you can stretch the picture

            this.$refs.fitToPage.setAttribute("class", "preview-icon glow");
            setTimeout(() => {
              this.$refs.fitToPage.setAttribute("class", "preview-icon active");
              setTimeout(() => {
                this.$refs.fitToPage.setAttribute("class", "preview-icon glow");
                setTimeout(() => {
                  this.$refs.fitToPage.setAttribute(
                    "class",
                    "preview-icon active"
                  );
                }, 800);
              }, 200);
            }, 800);
          }

          if (newHeight > this.max_size || newWidth > this.max_size) {
            //if either height or width is larger than the specified max pixels, we need to shrink it down
            let ratio = newHeight / newWidth;
            if (newHeight > newWidth) {
              newHeight = this.max_size;
              newWidth = this.max_size / ratio;
            } else {
              newWidth = this.max_size;
              newHeight = this.max_size * ratio;
            }
          }
          canvas.width = this.keepSquare
            ? Math.min(this.max_size, Math.max(newWidth, newHeight)) //if we keep it square, then we either want the largest size being the biggest side of the image, but not bigger than max_size
            : newWidth;
          canvas.height = this.keepSquare
            ? Math.min(this.max_size, Math.max(newWidth, newHeight))
            : newHeight;
          this.drawnImageHeight = newHeight;
          this.drawnImageWidth = newWidth;
          this.image = image;
          canvas.getContext("2d").drawImage(image, 0, 0, newWidth, newHeight);
          let dataUrl = canvas.toDataURL("image/webp");
          this.emitImage();
          return dataUrl;
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage() {
      this.$refs.canvas
        .getContext("2d")
        .clearRect(0, 0, this.max_size, this.max_size);
      this.fileName = "";
      this.fileType = "";
      this.fileModified = 0;
      this.imageSelected = false;
      this.image = "";
      this.file = null;
      this.imageObject = null;
      this.drawnImageHeight = 0;
      this.drawnImageWidth = 0;
      this.xOffset = 0;
      this.yOffset = 0;
      this.usingCustomURL = false;
      this.customURL = "";
      this.emitImage();
      this.$emit("remove");
    },
    resizeCanvas() {
      if (this.keepSquare && this.$refs.imageBox) {
        this.previewWidth = Math.min(
          this.$refs.imageBox.clientHeight,
          this.$refs.imageBox.clientWidth
        );
        this.previewHeight = Math.min(
          this.$refs.imageBox.clientHeight,
          this.$refs.imageBox.clientWidth
        );
      } else {
        if (this.$refs.imageBox) {
          this.previewWidth = this.$refs.imageBox.clientWidth;
          this.previewHeight = this.$refs.imageBox.clientHeight;
        }
      }
    },
  },
  computed: {
    hasPreview() {
      return (
        !navigator.userAgent.match(
          /(Android (1.0|1.1|1.5|1.6|2.0|2.1))|(Windows Phone (OS 7|8.0))|(XBLWP)|(ZuneWP)|(w(eb)?OSBrowser)|(webOS)|(Kindle\/(1.0|2.0|2.5|3.0))/
        ) &&
        window.FileReader &&
        !!window.CanvasRenderingContext2D
      );
    },
    supportsDragAndDrop() {
      const div = document.createElement("div");
      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        !("ontouchstart" in window || navigator.msMaxTouchPoints)
      );
    },
    computedClasses() {
      const classObject = {};
      classObject["dragging-over"] = this.draggingOver;
      return classObject;
    },
    fontSize() {
      return Math.min(0.04 * this.previewWidth, 21) + "px";
    },
  },
};
</script>

<style scoped lang="scss">
.picture-input {
  width: 100%;
  height: 100%;
  margin: auto auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 0 auto;
  overflow: hidden;
  justify-content: center;
  padding: $navbar-padding;
}
.preview-tools {
  display: flex;
  flex-direction: column;
  align-content: bottom;
  height: 100%;
}
.preview-icon {
  font-size: 2em;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    color: $primary-300;
  }
  &:first-child {
    margin-top: auto;
  }
  &.glow {
    color: $info-500;
  }
}
.active {
  cursor: pointer;
  color: $grey-500;
}
.inactive {
  color: $grey-300;
}
.disabled {
  pointer-events: none;
}
.picture-preview {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 101;
}
.picture-preview.dragging-over {
  filter: brightness(0.5);
}
.canvas-overlay {
  position: relative;
  z-index: 102;
  pointer-events: none;
  margin: 1rem;
  padding: 0.5em;
  border: 0.3em dashed $grey-300;
  border-radius: $border-radius;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border-color 0.2s;
}
.picture-preview:hover ~ * {
  border-color: $primary-300;
  .canvas-overlay-text {
    color: $primary-500;
  }
}
.canvas-overlay .canvas-overlay-text {
  vertical-align: middle;
  text-align: center;
  font-size: 2em;
  line-height: 1;
  color: $grey-600;
  font-weight: 300;
  transition: color 0.2s;
}
input[type="file"] {
  display: none;
}
.button-container {
  display: flex;
  column-gap: $item-margin;
  height: 2.5rem;
  margin: $item-margin;
}
.youtube-icon {
  &:hover {
    color: red;
  }
}
</style>
